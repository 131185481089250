<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="vehicle_code">Vehicle Code</label>
          <p id="vehicle_code">{{ make.code ? make.code : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="nvic">NVIC</label>
          <p id="nvic">{{ make.nvic ? make.nvic : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="make">Make</label>
          <p id="make">{{ make.make ? make.make : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="family">Model</label>
          <p id="family">{{ make.family ? make.family : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="family">Body Type</label>
          <p id="family">{{ make.style ? make.style : "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="year">Year</label>
          <p id="year">{{ make.year ? make.year : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="month">Month</label>
          <p id="month">{{ make.mth ? make.mth : "N/A" }}</p>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="variant">Variant</label>
          <p id="variant">{{ make.variant ? make.variant : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="series">Series</label>
          <p id="series">{{ make.series ? make.series : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="transmission">Transmission</label>
          <p id="transmission">
            {{ make.autoMan ? make.autoMan : "N/A" }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="drive">Drive</label>
          <p id="drive">
            {{ make.drv ? make.drv : "N/A" }}
          </p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cc">CC</label>
          <p id="cc">{{ make.cc ? make.cc : "N/A" }}</p>
        </div>
      </vs-col>
       <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="width">Width</label>
          <p id="width">{{ make.width ? make.width : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="size">Size</label>
          <p id="size">{{ make.size ? make.size : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cylinder">Cylinder</label>
          <p id="cylinder">{{ make.cylinder ? make.cylinder : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="engine">Engine</label>
          <p id="engine">{{ make.engine ? make.engine : "N/A" }}</p>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="cylinderConfiguration">Cylinder Configuration</label>
          <p id="cylinderConfiguration">
            {{
              make.cylinderConfiguration ? make.cylinderConfiguration : "N/A"
            }}
          </p>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>


<script>
import { mapActions } from "vuex";
export default {
  name: "MakeDetails",

  data: () => ({
    make: {},
    back() {
      this.$router.push({ name: "Make" });
    },
  }),

  methods: {
    ...mapActions("make", ["makeDetails"]),
    getMakeDetail(id) {
      let self = this;
      this.makeDetails(id).then((res) => {
        this.make = res.data.data;
      });
    },
  },

  created() {
    let makeId = this.$route.params.id;
    this.getMakeDetail(makeId);
  },
};
</script>  